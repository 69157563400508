// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

type Target = Disableable | Focusable
type Disableable = HTMLButtonElement
type Focusable = HTMLElement

const splashDialog = document.querySelector<HTMLDialogElement>('dialog.js-splash-dialog')

if (splashDialog) document.addEventListener('keydown', handleKeyDown)
if (splashDialog) document.addEventListener('click', handleNonSplashDialogClick)

function handleKeyDown(event: KeyboardEvent): void {
  // TODO: Refactor to use data-hotkey
  /* eslint eslint-comments/no-use: off */
  /* eslint-disable @github-ui/ui-commands/no-manual-shortcut-logic */
  if (!splashDialog) return
  if (event.key === 'Escape' || event.key === 'Esc') {
    splashDialog.querySelector<HTMLButtonElement>('button')!.click()
    event.stopPropagation()
  } else if (event.key === 'Tab') {
    restrictTabBehavior(event)
  }
  /* eslint-enable @github-ui/ui-commands/no-manual-shortcut-logic */
}

window.addEventListener('load', function () {
  splashDialog?.querySelector<HTMLButtonElement>('a')?.focus()
})

function handleNonSplashDialogClick(event: Event) {
  const {target} = event
  if (!(target instanceof Node)) return

  if (!splashDialog!.contains(target)) {
    splashDialog!.querySelector<HTMLButtonElement>('button')!.click()
  }
}

on('click', '.js-dismiss-splash-dialog', function () {
  if (splashDialog) document.removeEventListener('keydown', handleKeyDown)
  if (splashDialog) document.removeEventListener('click', handleNonSplashDialogClick)
})

on('click', '.js-dismiss-splash-dialog-link', function () {
  splashDialog?.querySelector<HTMLButtonElement>('button')?.click()
})

function focusable(el: Target): boolean {
  return el.tabIndex >= 0 && !(el as Disableable).disabled && visible(el)
}

function visible(el: Target): boolean {
  return (
    !el.hidden &&
    (!(el as Disableable).type ||
      // @ts-expect-error hidden is not a valid button type
      (el as Disableable).type !== 'hidden') &&
    (el.offsetWidth > 0 || el.offsetHeight > 0)
  )
}

function restrictTabBehavior(event: KeyboardEvent): void {
  if (!splashDialog) return
  event.preventDefault()

  const elements: Target[] = Array.from(splashDialog.querySelectorAll<HTMLElement>('a, button')).filter(focusable)
  if (elements.length === 0) return

  const movement = event.shiftKey ? -1 : 1
  const root = splashDialog.getRootNode() as Document | ShadowRoot
  const currentFocus = splashDialog.contains(root.activeElement) ? root.activeElement : null
  let targetIndex = movement === -1 ? -1 : 0

  if (currentFocus instanceof HTMLElement) {
    const currentIndex = elements.indexOf(currentFocus)
    if (currentIndex !== -1) {
      targetIndex = currentIndex + movement
    }
  }

  if (targetIndex < 0) {
    targetIndex = elements.length - 1
  } else {
    targetIndex = targetIndex % elements.length
  }

  elements[targetIndex]!.focus()
}
